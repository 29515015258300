<template>
  <div id="order-view-actions-spec-claimed">
    <OrderViewActionClaim
      v-if="isQuoteOwner"
      btn-icon="SettingsIcon"
      :btn-text="$t('action.handle_this_claim')"
      :claim-title="$t('action.handle_this_claim')"
      variant="danger"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionsSpecClaimed',

  components: {
    OrderViewActionClaim: () => import('@/views/order/OrderViewActionClaim.vue'),
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
  },
}
</script>
